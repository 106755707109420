<template>
  <div class="row">
    <div class="col-12 col-lg-6 g">
        <div class="card">
            <div class="card-header">
                <h5>
                    رسالة كود التفعيل
                </h5>
            </div>
            <div class="card-body">
                <div class="form-group">
                  <textarea class="form-control" v-model="settings.otpMessage" rows="3"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6 g">
        <div class="card">
            <div class="card-header">
                <h5>
                    رسالة استعادة كلمة المرور
                </h5>
            </div>
            <div class="card-body">
                <div class="form-group">
                  <textarea class="form-control" v-model="settings.resetMessage" rows="3"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6 g">
        <div class="card">
            <div class="card-header">
                <h5>
                    رسالة ايقاف النظام
                </h5>
                <strong class="text-danger">سيتم ايقاف النظام اذا كان عدد الحروف اكثر من 10 حروف٫ لن يتم ايقاف الادمن. <br>
                وسيتم ايقاف الvue فقط.</strong>
            </div>
            <div class="card-body">
                <div class="form-group">
                  <textarea class="form-control" v-model="settings.vueStopMessage" rows="3"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6 g">
        <div class="card card-body">
            <div class="form-group">
              <label for="">معالجة تقارير الطلاب</label>
              <select class="form-control" v-model="settings.students_reports_worker">
                <option value="work">فعالة</option>
                <option value="stop">ايقاف المعالجة</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">معالجة تقارير المعملين</label>
              <select class="form-control" v-model="settings.teachers_reports_worker">
                <option value="work">فعالة</option>
                <option value="stop">ايقاف المعالجة</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">السماح بالتسجيل للمستخدمين</label>
              <select class="form-control" v-model="settings.resgister">
                <option value="work">سماح</option>
                <option value="stop">ايقاف التسجيل</option>
              </select>
            </div>
            <div class="form-group">
              <label for="">السماح باختصار الروابط</label>
              <select class="form-control" v-model="settings.short_links">
                <option value="work">سماح</option>
                <option value="stop">ايقاف</option>
              </select>
            </div>
        </div>
    </div>
    <div class="col-12 text-center g">
        <button class="btn btn-primary" @click="save()">
            حفظ الاعدادات
        </button>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            jwt: localStorage.getItem('jwt'),
            settings: {}
        };
    },
    created(){
        var g = this;
        $.post(api + '/admin/settings/options', {
            jwt: this.jwt,
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.settings = {};
                r.response.forEach(function(a){
                    g.settings[a.key] = a[a.type];
                })
            }
        }).fail(function(){
            g.loading = false;
        })
    },
    methods: {
        save(){
            var g = this;
        $.post(api + '/admin/settings/options-save', {
            jwt: this.jwt,
            settings: g.settings
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                alert("تم الحفظ", 100)
            }
        }).fail(function(){
            g.loading = false;
        })
        }
    }
}
</script>

<style>

</style>